export const brakeType = {
  id: 'brakeType',
  key: 'brake_type',
  label: 'Brake type',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'placement',
  queryParamNames: ['pub_brakeType'],
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      {  key: 'front', value: 'front', label: 'Front' },
      { key: 'rear', value: 'rear', label: 'Rear' },
      { key: 'set', value: 'set', label: 'Set' }
    ]
  }
}
