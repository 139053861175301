export const gears = ({ mandatory = true, type = '' }) => ({
  id: type != '' ? `${type}Gears` : 'gears',
  key: type != '' ? `${type}_gears` : 'gears',
  label: 'Gears',
  mandatory: mandatory,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'gears',
  inputType: 'DROPDOWN',
  queryParamNames: type != '' ? [`pub_${type}Gears`] : ['pub_gears'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '1', value: '1', label: '1' },
      { key: '2', value: '2', label: '2' },
      { key: '3', value: '3', label: '3' },
      { key: '4', value: '4', label: '4' },
      { key: '5', value: '5', label: '5' },
      { key: '6', value: '6', label: '6' },
      { key: '7', value: '7', label: '7' },
      { key: '8', value: '8', label: '8' },
      { key: '9', value: '9', label: '9' },
      { key: '10', value: '10', label: '10' },
      { key: '11', value: '11', label: '11' },
      { key: '12', value: '12', label: '12' },
      { key: '13', value: '13', label: '13' },
    ]
  }
})
