import CockpitCategoryLogo from '../../assets/part_categories/cockpit.png'
import { frameMaterial, gripsType, handlebarTypes, handlebarWidth, steererType, stemLength } from '../specifications'

export const cockpit = {
  key: 'cockpit',
  label: 'Cockpit',
  logo: CockpitCategoryLogo,
  subcategories: [
    {
      key: 'cockpit_handlebar',
      value: 'cockpit_handlebar',
      label: 'Handlebar',
      specifications: [
        handlebarTypes,
        handlebarWidth,
        frameMaterial({ mandatory: false, collapsible: false, isFilterable: true })
      ]
    },
    {
      key: 'cockpit_stem',
      value: 'cockpit_stem',
      label: 'Stem',
      specifications: [stemLength]
    },
    {
      key: 'cockpit_grips',
      value: 'cockpit_grips',
      label: 'Grips',
      specifications: [gripsType]
    },
    {
      key: 'cockpit_headset',
      value: 'cockpit_headset',
      label: 'Headset',
      specifications: [steererType]
    }
  ]
}
