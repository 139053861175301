export const wheelSize = ({ collapsible = true, type = '', isFilterable = false }) => ({
  id: type != '' ? `${type}WheelSize` : 'wheelSize',
  key: type != '' ? `${type}_wheel_size` : 'wheel_size',
  label: 'Wheel size',
  mandatory: false,
  isOpen: false,
  isFilterable: isFilterable,
  collapsible: collapsible,
  inputType: 'DROPDOWN',
  queryParamNames: [type != '' ? `pub_${type}WheelSize` : 'pub_wheelSize'],
  type: 'SelectMultipleFilter',
  translationGroup: 'wheel_size',
  config: {
    schemaType: 'enum',
    options: [
      { key: '26', value: '26', label: '26”' },
      { key: '27.5', value: '27.5', label: '27.5” (650b)' },
      { key: '700C', value: '700C', label: '28” (700c)' },
      { key: '29', value: '29', label: '29”' },
      { key: 'other', value: 'other', label: 'Other' },
    ],
  }
})
