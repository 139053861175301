export const crankArmLength = {
  id: 'crankArmLength',
  key: 'crank_arm_length',
  label: 'Crank arm length',
  mandatory: false,
  inputType: 'TEXT',
  textInputType: 'number',
  type: 'SelectMultipleFilter',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 150,
  maxValue: 200
}
