export const seatLength = {
  id: 'seatLength',
  key: 'seat_length',
  label: 'Seat length',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 0,
  maxValue: 600
}
