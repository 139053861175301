export const bottomBracketWidth = {
  id: 'bottomBracketWidth',
  key: 'bottom_bracket_width',
  label: 'Bottom bracket width',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 40,
  maxValue: 150
}
