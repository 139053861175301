export const suspensionMounting = {
  id: 'suspensionMounting',
  key: 'suspension_mounting',
  label: 'Suspension mounting',
  isOpen: false,
  mandatory: false,
  isFilterable: true,
  queryParamNames: ['pub_suspensionMounting'],
  inputType: 'CHECKBOX',
  type: 'SelectMultipleFilter',
  translationGroup: 'suspension_mounting',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'trunnion', value: 'trunnion', label: 'Trunnion' },
      { key: 'standard', value: 'standard', label: 'Standard' }
    ]
  }
}