export const gripsType = {
  id: 'gripsType',
  key: 'grips_type',
  label: 'Grips type',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'grips_type',
  queryParamNames: ['pub_gripsType'],
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'lock_on', value: 'lock_on', label: 'Lock-On' },
      { key: 'push_on', value: 'push_on', label: 'Push-On' },
      { key: 'bar_tape', value: 'bar_tape', label: 'Bar tape' }
    ]
  }
}
