export const chainringTeeth = {
  id: 'chainringTeeth',
  key: 'chainring_teeth',
  label: 'Chainring teeth',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  minValue: 0,
  maxValue: 100
}
