export const dropperDiameter = {
  id: 'dropperDiameter',
  key: 'dropper_diameter',
  label: 'Dropper diameter',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'dropper_diameter',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_dropperDiameter'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '27_2', value: '27_2', label: '27.2mm' },
      { key: '28_6', value: '28_6', label: '28.6mm' },
      { key: '30_9', value: '30_9', label: '30.9mm' },
      { key: '31_6', value: '31_6', label: '31.6mm' },
      { key: '31_8', value: '31_8', label: '31.8mm' },
      { key: '34_9', value: '34_9', label: '34.9mm' },
    ]
  }
}
