export const suspensionStroke = {
  id: 'suspensionStroke',
  key: 'suspension_stroke',
  label: 'Suspension stroke',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 40,
  maxValue: 220
}
