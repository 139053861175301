export const axleDimension = {
  id: 'axleDimension',
  key: 'axle_dimension',
  label: 'Axle dimension',
  mandatory: false,
  isFilterable: true,
  inputType: 'DROPDOWN',
  isOpen: false,
  translationGroup: 'axle_dimension',
  queryParamNames: ['pub_axleDimension'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '30', value: '30', label: '30mm' },
      { key: '29_9', value: '29_9', label: '29.9mm' },
      { key: '22_24', value: '22_24', label: '22/24mm (DUB)' },
      { key: '25', value: '25', label: '25mm (K-Type/Ultra-Torque)' },
      { key: '24', value: '24', label: '24mm' },
      { key: '19', value: '19', label: '19mm' },
      { key: 'square_taper', value: 'square_taper', label: 'Square taper' },
      { key: 'octalink', value: 'octalink', label: 'Octalink' },
      { key: 'm30', value: 'm30', label: 'M30' },
      { key: 'isis', value: 'isis', label: 'ISIS' },
      { key: 'american', value: 'american', label: 'American' },
      { key: 'mid', value: 'mid', label: 'Mid' },
    ]
  }
}
