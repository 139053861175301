import WheelsCategoryLogo from '../../assets/part_categories/wheels.png'
import { frameMaterial, hubsPlacement, innerRimWidth, wheelSize, wheelsPlacement } from '../specifications'

export const wheels = {
  key: 'wheels',
  label: 'Wheels',
  logo: WheelsCategoryLogo,
  subcategories: [
    {
      key: 'wheels_complete',
      value: 'wheels_complete',
      label: 'Complete wheels',
      specifications: [wheelsPlacement]
    },
    {
      key: 'wheels_hubs',
      value: 'wheels_hubs',
      label: 'Hubs',
      specifications: [hubsPlacement]
    },
    {
      key: 'wheels_rims',
      value: 'wheels_rims',
      label: 'Rims',
      specifications: [
        wheelSize({ collapsible: false, isFilterable: true }),
        frameMaterial({ mandatory: false, collapsible: false, isFilterable: true }),
        innerRimWidth
      ]
    }
  ]
}
