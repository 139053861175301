import { diskDiameter } from "./diskDiameter"
import { gears } from "./gears"

export const rotorPlacement = {
  id: 'rotorPlacement',
  key: 'rotor_placement',
  label: 'Rotor placement',
  mandatory: false,
  inputType: 'MULTISELECT',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { 
        key: 'front',
        value: 'front',
        label: 'Front',
        options: [diskDiameter({ type: 'front' })]
      },
      {
        key: 'rear',
        value: 'rear',
        label: 'Rear',
        options: [diskDiameter({ type: 'rear' })]
      },
      {
        key: 'set',
        value: 'set',
        label: 'Set',
        options: [
          diskDiameter({ type: 'front' }),
          diskDiameter({ type: 'rear' })
        ]
      }
    ]
  }
}
