export const rotorMounting = {
  id: 'rotorMounting',
  key: 'rotor_mounting',
  label: 'Rotor mounting',
  mandatory: false,
  inputType: 'CHECKBOX',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '6_hole', value: '6_hole', label: '6-Hole' },
      { key: 'centerlock', value: 'centerlock', label: 'Centerlock' },
    ]
  }
}
