export const suspensionLength = {
  id: 'suspensionLength',
  key: 'suspension_length',
  label: 'Suspension length',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 100,
  maxValue: 290,
}
