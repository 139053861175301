export const dropperActivation = {
  id: 'dropperActivation',
  key: 'dropper_activation',
  label: 'Dropper activation',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'dropper_activation',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_dropperActivation'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'mechanical', value: 'mechanical', label: 'Mechanical' },
      { key: 'electric', value: 'electric', label: 'Electric' },
      { key: 'hydraulic', value: 'hydraulic', label: 'Hydraulic' },
    ]
  }
}
