export const frameSize = {
  id: 'frameSize',
  key: 'frame_size',
  label: 'Frame size',
  mandatory: true,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  translationGroup: 'frame_size',
  queryParamNames: ['pub_frameSize'],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'xs', value: 'xs', label: 'XS' },
      { key: 's', value: 's', label: 'S' },
      { key: 'm', value: 'm', label: 'M' },
      { key: 'l', value: 'l', label: 'L' },
      { key: 'xl', value: 'xl', label: 'XL' },
      { key: 'xxl', value: 'xxl', label: 'XXL' },
    ],
  }
}
