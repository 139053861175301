export const innerRimWidth = {
  id: 'innerRimWidth',
  key: 'inner_rim_width',
  label: 'Inner rim width',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 0,
  maxValue: 150
}
