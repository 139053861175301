import { wheelSize } from "./wheelSize";

export const wheelsPlacement = {
  id: 'wheelsPlacement',
  key: 'wheels_placement',
  label: 'Wheels placement',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'placement',
  queryParamNames: ['pub_wheelsPlacement'],
  inputType: 'MULTISELECT',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      {
        schemaType: 'enum',
        key: 'front',
        value: 'front',
        label: 'Front',
        options: [wheelSize({ collapsible: false, type: 'front' })]
      },
      {
        key: 'rear',
        value: 'rear',
        label: 'Rear',
        options: [wheelSize({ collapsible: false, type: 'rear' })]
      },
      {
        key: 'set',
        value: 'set',
        label: 'Set',
        options: [
          wheelSize({ collapsible: false, type: 'front' }),
          wheelSize({ collapsible: false, type: 'rear' })
        ]
      }
    ]
  }
}
