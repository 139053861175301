import { hubStandard } from "./hubStandard";

export const hubsPlacement = {
  id: 'hubsPlacement',
  key: 'hubs_placement',
  label: 'Hubs placement',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'placement',
  queryParamNames: ['pub_hubsPlacement'],
  inputType: 'MULTISELECT',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { 
        key: 'front',
        value: 'front',
        label: 'Front',
        options: [hubStandard({ type: 'front' })]
      },
      {
        key: 'rear',
        value: 'rear',
        label: 'Rear',
        options: [hubStandard({ type: 'rear' })]
      },
      {
        key: 'set',
        value: 'set',
        label: 'Set',
        options: [
          hubStandard({ type: 'front' }),
          hubStandard({ type: 'rear' })
        ]
      }
    ]
  }
}